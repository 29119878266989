<script>
import { Doughnut , mixins} from 'vue-chartjs'

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveData],
  data() {
    return {
      chartData: '',
      errors: {
        code: '',
        status: '',
        headers: ''
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData)
  },
  created () {
    this.$http.get(`user-gender`)
    .then((response) => {
      const responseData = response.data;
      this.chartData = {
        labels: ['Male ' + '(' + responseData.percentMale + ')', 'Female '+ '(' + responseData.percentFemale + ')'],
        datasets: [
          {
            backgroundColor: [
              '#0d91e3',
              '#8644a2'
            ],
            data: [responseData.male, responseData.female]
          }
        ]
      },{responsive: true, maintainAspectRatio: true}
    })
    .catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
}
</script>
