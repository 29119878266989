    <template>
    <div class="animated fadeIn">
        <b-row>
            <b-col>
              <b-card no-body class="bg-primary">
                <b-card-body class="pb-0">
                  <h4 class="mb-0">{{ this.default.newUser }}</h4>
                  <p>Total New User</p>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col>
              <b-card no-body class="bg-primary">
                <b-card-body class="pb-0">
                  <h4 class="mb-0" v-if="this.resultUserTarget.percent == null">{{ this.default.percent}}</h4>
                    <h4 class="mb-0" v-else>{{ this.resultUserTarget.percent }}</h4>
                  <p>Percent New User Target from New User</p>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        <b-row>
            <b-col md="12">
                <b-card header="User Diagrams">
                    <h5>Target New User</h5>
                    <form @submit.prevent="targetPost">
                    <b-form-group>
                        <b-input-group>
                        <b-form-input type="number" v-model="resultUserTarget.target"></b-form-input>
                        <!-- Attach Right button -->
                        <b-input-group-append>
                            <b-button type="submit" variant="primary">Submit</b-button>
                        </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    </form>
                <br>
                <br>
                <b-row>
                    <b-col sm="12" lg="6">
                    <b-row>
                        <b-col sm="12">
                        <h4 style="text-align:center">Users Based On Age</h4>
                        </b-col>
                    </b-row>
                    <bar-user-age chartId="chart-bar-01"/>
                    </b-col>
                    <b-col sm="12" lg="6">
                    <b-row>
                        <b-col sm="12">
                        <h4 style="text-align:center">Users Based On Gender</h4>
                        </b-col>
                    </b-row>
                    <doughnut-user-gender chartId="chart-doughnut-01"/>
                    </b-col>
                </b-row>
                <br><br>
                <b-row>
                        <b-col sm="12" lg="6">
                        <b-row>
                            <b-col sm="12">
                            <h4 style="text-align:center">New User Based on Gender</h4>
                            </b-col>
                        </b-row>
                            <doughnut-new-user chartId="chart-doughnut-01"/>
                        </b-col>
                        <b-col sm="12" lg="6">
                        <b-row>
                            <b-col sm="12">
                            <h4 style="text-align:center">New User Based on Age</h4>
                            </b-col>
                        </b-row>
                            <bar-new-user chartId="chart-bar-01"/>
                        </b-col>
                </b-row>
                <br><br>
                <b-row>
                    <b-col sm="12" lg="6">
                    <b-row>
                        <b-col sm="12">
                        <h4 style="text-align:center">Active Users Based On Age</h4>
                        </b-col>
                    </b-row>
                    <bar-active-user chartId="chart-bar-01"/>
                    </b-col>
                    <b-col sm="12" lg="6">
                    <b-row>
                        <b-col sm="12">
                        <h4 style="text-align:center">Active Users Based On Gender</h4>
                        </b-col>
                    </b-row>
                    <doughnut-active-user chartId="chart-doughnut-01"/>
                    </b-col>
                </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
    </template>

    <script>
    import DoughnutUserGender from './charts/DoughnutUserGender'
    import DoughnutNewUser from './charts/DoughnutNewUser'
    import DoughnutActiveUser from './charts/DoughnutActiveUser'
    import BarUserAge from './charts/BarUserAge'
    import BarNewUser from './charts/BarNewUser'
    import BarActiveUser from './charts/BarActiveUser'

    export default {
    name: 'dashboard',
    computed: {
        loggedIn(){
        return this.$store.getters.loggedIn
        }
    },
    components: {
        DoughnutUserGender,
        DoughnutNewUser,
        DoughnutActiveUser,
        BarUserAge,
        BarNewUser,
        BarActiveUser
    },
    data: function () {
        return {
        // Dismiss alert script
        totalUser: '',
        activeUser: '',
        newUser: '',
        userTarget: '',
        dismissSecs: 10,
        dismissCountDown: 0,
        showDismissibleAlert: false,

        selected: 'Month',
        default: {
            newUser: '',
            percent: ''
        },
        resultUserTarget: {
            target: '',
            percent: null
        },
        errors: {
            code: '',
            status: '',
            headers: ''
        }
        }
    },
    created() {
        this.$http.get(`show-target-user`)
            .then((result) => {
                this.default.newUser = result.data.NewUser;
                this.default.percent = result.data.percent;
            }).catch((error) => {
                if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.status = error.response.data.meta.code;
                    this.errors.headers = error.response.headers;
                }
            })
    },
    methods: {
        targetPost() {
            this.$http.post(`show-target-user`, {
              target: this.resultUserTarget.target
            }).then((result) => {
                this.resultUserTarget.percent = result.data.Percent;
            }).catch((error) => {
                if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.status = error.response.data.meta.code;
                    this.errors.headers = error.response.headers;
                }
            })
        },
        // Dissmiss Alert Script
        countDownChanged (dismissCountDown) {
        this.dismissCountDown = dismissCountDown
        },
        showAlert () {
        this.dismissCountDown = this.dismissSecs
        },

        variant (value) {
        let $variant
        if (value <= 25) {
            $variant = 'info'
        } else if (value > 25 && value <= 50) {
            $variant = 'success'
        } else if (value > 50 && value <= 75) {
            $variant = 'warning'
        } else if (value > 75 && value <= 100) {
            $variant = 'danger'
        }
        return $variant
        },
        flag (value) {
        return 'flag-icon flag-icon-' + value
        }
    }
    }
    </script>

    <style>
    /* IE fix */
    #card-chart-01, #card-chart-02 {
        width: 100% !important;
    }
    </style>
